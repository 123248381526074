function _0x2699(_0x25335b, _0x1576b3) {
    var _0x1d7eab = _0x4e4e();
    return _0x2699 = function (_0x36bf41, _0xcaf7a5) {
        _0x36bf41 = _0x36bf41 - (-0x1 * 0x1589 + 0x8be + 0xe09);
        var _0x415142 = _0x1d7eab[_0x36bf41];
        if (_0x2699['aFxHlg'] === undefined) {
            var _0x2add69 = function (_0x11a48a) {
                var _0x18c97c = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=';
                var _0x461fd6 = '', _0x5ba95c = '';
                for (var _0x373925 = 0x221 + 0x58 + -0x279 * 0x1, _0x2f1525, _0xe999dd, _0x2637be = 0x2039 + 0x8bf + -0xa3e * 0x4; _0xe999dd = _0x11a48a['charAt'](_0x2637be++); ~_0xe999dd && (_0x2f1525 = _0x373925 % (-0x26a1 + -0xee3 + 0x11d8 * 0x3) ? _0x2f1525 * (-0x29c + 0x1086 + -0xdaa * 0x1) + _0xe999dd : _0xe999dd, _0x373925++ % (-0x5 * 0x283 + 0x1 * 0x22be + -0x162b)) ? _0x461fd6 += String['fromCharCode'](0x7b * -0x1f + -0x15 * 0x59 + 0x7bb * 0x3 & _0x2f1525 >> (-(0x219b + -0x7ab + 0x19ee * -0x1) * _0x373925 & -0x1579 * 0x1 + 0x2 * -0xcec + 0x1 * 0x2f57)) : 0x10bd + 0x1e69 + 0x55 * -0x8e) {
                    _0xe999dd = _0x18c97c['indexOf'](_0xe999dd);
                }
                for (var _0x33a9ff = 0x6e5 + 0x1 * -0xd9a + -0x6b5 * -0x1, _0x164ba9 = _0x461fd6['length']; _0x33a9ff < _0x164ba9; _0x33a9ff++) {
                    _0x5ba95c += '%' + ('00' + _0x461fd6['charCodeAt'](_0x33a9ff)['toString'](-0x71 * -0x20 + 0x18c8 * 0x1 + -0x26d8))['slice'](-(-0x2222 + 0x1c6 * 0x16 + 0x8 * -0x9c));
                }
                return decodeURIComponent(_0x5ba95c);
            };
            _0x2699['XXVfpz'] = _0x2add69, _0x25335b = arguments, _0x2699['aFxHlg'] = !![];
        }
        var _0x987d58 = _0x1d7eab[0x15f4 + 0x2569 + -0x3b5d], _0x56816f = _0x36bf41 + _0x987d58, _0x39ede0 = _0x25335b[_0x56816f];
        return !_0x39ede0 ? (_0x415142 = _0x2699['XXVfpz'](_0x415142), _0x25335b[_0x56816f] = _0x415142) : _0x415142 = _0x39ede0, _0x415142;
    }, _0x2699(_0x25335b, _0x1576b3);
}
var _0x1185a4 = _0x2699;
(function (_0x17de86, _0x38d226) {
    var _0x5d41ca = _0x2699, _0x2b5038 = _0x17de86();
    while (!![]) {
        try {
            var _0x15036e = parseInt(_0x5d41ca(0x150)) / (0x26f5 * -0x1 + -0x1de1 + 0x44d7) * (parseInt(_0x5d41ca(0x14a)) / (-0xf2 * -0x8 + 0x9 * -0xff + -0x169 * -0x1)) + parseInt(_0x5d41ca(0x147)) / (-0x7 * 0x38 + -0x3 * -0xba5 + -0x2164) + parseInt(_0x5d41ca(0x13f)) / (-0x784 + -0x12ee + 0x8d2 * 0x3) * (parseInt(_0x5d41ca(0x155)) / (0x23d4 + -0xa1c + 0x81 * -0x33)) + -parseInt(_0x5d41ca(0x14d)) / (-0x4d2 + 0x639 + 0x1 * -0x161) * (parseInt(_0x5d41ca(0x14b)) / (-0x1 * -0x148b + 0x1a5c + -0x2ee0)) + parseInt(_0x5d41ca(0x13e)) / (0xf9f + -0x1 * -0x2051 + -0x1c * 0x1b6) * (-parseInt(_0x5d41ca(0x154)) / (0x1d28 + -0x4 * -0x4d0 + -0x305f)) + parseInt(_0x5d41ca(0x144)) / (0x42 * 0x19 + 0x1d8d + -0x23f5) + -parseInt(_0x5d41ca(0x142)) / (-0xec8 + 0x2 * 0x60b + 0x2bd);
            if (_0x15036e === _0x38d226)
                break;
            else
                _0x2b5038['push'](_0x2b5038['shift']());
        } catch (_0x389fca) {
            _0x2b5038['push'](_0x2b5038['shift']());
        }
    }
}(_0x4e4e, 0x3a323 * -0x2 + 0x93238 + 0x2 * 0x1a0d8));
import { http } from '@/utils/axios';
import _0x10254b from '@/utils/lang';
var api = {
    'header': (_0x1185a4(0x14e) + _0x1185a4(0x149) + _0x1185a4(0x140) + _0x1185a4(0x148))[_0x1185a4(0x14f)](_0x10254b),
    'homePage': (_0x1185a4(0x14e) + _0x1185a4(0x149) + _0x1185a4(0x145) + _0x1185a4(0x151))[_0x1185a4(0x14f)](_0x10254b),
    'ipLang': (_0x1185a4(0x14e) + _0x1185a4(0x149) + _0x1185a4(0x14c) + 'ng')[_0x1185a4(0x14f)](_0x10254b)
};
export var header = function header() {
    var _0x1c8be9 = _0x1185a4, _0x380b87 = {
            'qwtAV': function (_0x2fa9c5, _0x3395c8) {
                return _0x2fa9c5(_0x3395c8);
            }
        };
    return _0x380b87[_0x1c8be9(0x146)](http, api[_0x1c8be9(0x141)]);
};
export var homePage = function homePage() {
    var _0x1c6863 = _0x1185a4, _0x3a8696 = {
            'oGGqq': function (_0x470047, _0x3b81d2) {
                return _0x470047(_0x3b81d2);
            }
        };
    return _0x3a8696[_0x1c6863(0x143)](http, api[_0x1c6863(0x153)]);
};
function _0x4e4e() {
    var _0x57ce40 = [
        'ndGWnZaWmg12q1PtrW',
        'Ag9Tzs9OB21LxW',
        'CxD0qvy',
        'otm1ndi3yxfez0P4',
        'CI9Syw5N',
        'sw50zxjMywnLlW',
        'nZm4oe9zDgLMDq',
        'ndjAEgXdq3u',
        'Ag9Tzs9PCc9Syq',
        'mJm2mJK4BxP6z3r2',
        'l2fWAs9MCM9UDa',
        'y29Uy2f0',
        'ovPTuLfdrW',
        'CgfNzs9Syw5N',
        'Axbmyw5N',
        'Ag9TzvbHz2u',
        'ownTrhrYBW',
        'mZGWwev6Ahvd',
        'D2npvgW',
        'mtq0mJq4mgviwgvUyG',
        'nJmWngzwrMPUtq',
        'Ag9Tzs9OzwfKzq',
        'AgvHzgvY',
        'mJa4nte3muXqrK1yvW',
        'B0DhCxe'
    ];
    _0x4e4e = function () {
        return _0x57ce40;
    };
    return _0x4e4e();
}
export var ipLang = function ipLang() {
    var _0x37d995 = _0x1185a4, _0x441d92 = {
            'wcOTl': function (_0x54ce44, _0x1c1a51) {
                return _0x54ce44(_0x1c1a51);
            }
        };
    return _0x441d92[_0x37d995(0x156)](http, api[_0x37d995(0x152)]);
};